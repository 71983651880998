/* Custom styles for the suggestions container */
.react-autosuggest__suggestions-container {
  position: absolute;
  top: 61px;
  z-index: 1000;
  min-width: 150px;
}
@media (max-width: 991px) {
.offcanvas .react-autosuggest__suggestions-container{
  top: 126px;
}
}

.react-autosuggest__suggestions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #fff5e0;
  border: 1px solid #ccc;
}

.react-autosuggest__suggestion {
  padding: 10px;
  cursor: pointer;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
